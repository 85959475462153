import React from 'react';
import { useRouteError } from 'react-router-dom';
import Header from '../components/Header';

const Error = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div class="error-page">
      <h1 class="error-title">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <button
        onClick={() => {
          window.location.href = '/';
        }}
        className="button transparent">
        back to Home
      </button>
    </div>
  );
};

export default Error;
