import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import Loader from '../components/Loader';
import Table from '../components/Table';
import Poweredby from '../components/Poweredby';
import { useSearchParams, useParams } from 'react-router-dom';
import ApiService from '../services/api-service';
import { Badge, Container, Spacer } from '@nextui-org/react';

const VerifyHash = () => {
  const [init, setInit] = useState(false);
  const [step, setStep] = useState(1);
  const [isVerified, setIsVerified] = useState(false);
  const [fetchingHashes, setFetchingHashes] = useState([]);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [successes, setSuccesses] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { hash } = useParams();

  /*
        Normal path:
        Step 1 - Do init: Check file count, set init to true and show welcome screen
        Step 2 - Do captcha, set isVerified if captcha works, san skip if verified is already set
        Step 3 - Show drag and drop zone
        Step 4 - Fetch files, show spinner go to 3 if error
        Step 5 - show results, go to 3 if "check another"
    */

  const verifyDelay = 500; // ms

  const ApiEndpoint = process.env.REACT_APP_API_URL;
  const ApiReport = '/Hashes/generateReport';
  const recapsitekey = process.env.REACT_APP_RECAP_SITE_KEY;

  // init
  useEffect(() => {
    if (init === false) {
      //setting background as transparent in the embedded component
      //TODO find a better way of doing this. Problem is that by default css is not scoped on react
      // document.body.style.backgroundColor = 'transparent';
      document.documentElement.style.setProperty(
        '--backgroundColor',
        'transparent'
      );

      const theme = searchParams.get('theme');
      if (theme === 'dark') {
        // this is just used for testing for a hypothetical dark page to be embedded on
        //   document.documentElement.style.setProperty(
        //     '--backgroundColor',
        //     '#121212'
        //   );
        document.documentElement.style.setProperty('--textColor', 'white');
      }

      const accent = searchParams.get('accent');
      if (CSS.supports('color', '#' + accent)) {
        document.documentElement.style.setProperty(
          '--accentColor',
          '#' + accent
        );
      }

      //setting language
      const lang = searchParams.get('lang');
      if (['en', 'it', 'de', 'fr'].includes(lang)) {
        i18n.changeLanguage(lang);
      } else {
        i18n.changeLanguage('en');
      }

      // set verified to skip captcha
      if (JSON.parse(localStorage.getItem('eseal.verified')) === true) {
        setIsVerified(true);
      }

      // start it
      setInit(true);
      setStep(2);
      setFetchingHashes([hash]);
    }
  }, [init, ApiEndpoint, searchParams, hash, i18n]);

  // set verified for future usage in same session
  useEffect(() => {
    if (isVerified === true) {
      localStorage.setItem('eseal.verified', true);
    }
  }, [isVerified]);

  // skip google captcha when already filled (same session)
  useEffect(() => {
    if (step === 2 && isVerified === true) {
      //            console.log('Captcha already verified');
      setStep(4);
      console.log('hash', hash, 'hasesh: ' + fetchingHashes);
    }
    if (step === 3) {
      setDisplayFiles([]);
    }

    if (step === 11) {
      setDisplayFiles([]);
    }
  }, [step, isVerified, setFetchingHashes, hash, fetchingHashes]);

  //console log current step
  useEffect(() => {
    console.log('👉 Step: ', step);
    //log fetching hashes
    console.log('👉 Fetching Hashes: ', fetchingHashes);
  }, [fetchingHashes, step]);

  function fetchFiles(fetchingHashes, ApiEndpoint, displayFiles, lang) {
    console.log('😂 fetching FILES', fetchingHashes);
    setLoading(true);
    fetchingHashes.forEach(async (hash) => {
      console.log('Hash for ', hash, ' :', hash);

      ApiService.verifyFile(hash)
        .then((response) => {
          if ('error' in response) {
            setStep(3);
            setError({
              code: 'api-down-with-file',
              file: hash,
            });
          } else if ('noSeal' in response) {
            setError({});
            let data = {
              base: {},
              data: { hashValues: [] },
            };
            // console.log(response);
            // console.log('##', data);
            setDisplayFiles((displayFiles) => [...displayFiles, data]);
          } else {
            setError({});
            //console.log('######', response);
            //                        console.log("Lang: " + i18n.language);
            response.report =
              ApiEndpoint +
              ApiReport +
              '/' +
              hash +
              '/0/' +
              i18n.language +
              '/' +
              response.userId;
            let data = {
              base: {},
              data: { hashValues: [response] },
            };
            // console.log(response);
            // console.log('##', data);
            setDisplayFiles((displayFiles) => [...displayFiles, data]);
            // set current volume of used files
            // setFilesCount(response.callCount.callCount);
          }
        })
        .catch((error) => {
          setStep(4);
          setError({
            code: 'api-down-with-file',
            file: hash,
          });
        });

      setLoading(false);
    });
  }

  // fetch files
  useEffect(() => {
    if (fetchingHashes.length === 0 || loading) {
      return;
    }
    fetchFiles(fetchingHashes, ApiEndpoint, displayFiles, i18n.language);
    setFetchingHashes([]);
  }, [fetchingHashes, ApiEndpoint, displayFiles, i18n, loading]);

  // after files fetch
  useEffect(() => {
    if (displayFiles.length > 0 && step === 4) {
      setError({});
      setTimeout(() => {
        setFetchingHashes([]);
        setSuccesses(countSuccesses(displayFiles));
        setStep(5);
      }, verifyDelay);
    }
  }, [displayFiles, step]);

  function countSuccesses(displayFiles) {
    return displayFiles.filter((x) => x.data.hashValues.length > 0).length;
  }

  return (
    <div id="embedded-form" className={`section step--${step}`}>
      <div id="used to center middle compoenent in flex"></div>
      <div className="container">
        {step === 2 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">{t('form.headline')}</h1>
              <h2 className="text-align-center">{t('form.lead')}</h2>
              <div className="text">
                <p className="text-align-center">{t('form.text')}</p>
              </div>
            </div>
            <div id="captcha" className="text">
              <div className="gcaptcha">
                <ReCAPTCHA
                  sitekey={recapsitekey}
                  size="normal"
                  onChange={() => {
                    if (step === 2) {
                      setStep(4);
                    }
                    setIsVerified(true);
                  }}
                  hl={i18n.language}
                />
              </div>
            </div>
          </>
        )}
        {step === 4 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">{t('form.headline')}</h1>
              <h2 className="text-align-center">{t('form.lead')}</h2>
              <div className="text">
                <p className="text-align-center">{t('form.text')}</p>
              </div>
            </div>
            <div id="verify">
              <Loader />
              <ul className="text-align-center">
                {fetchingHashes.map((item, index) => {
                  return (
                    <li key={index}>
                      {t('form.verify.testing', { filename: item })}
                    </li>
                  );
                })}
              </ul>
            </div>
            {error.code && (
              <>
                <Spacer y={2} />
                <Container fluid display="flex" justify="center">
                  <Badge color="error">Error {error.code}</Badge>
                </Container>
              </>
            )}
          </>
        )}
        {step === 5 && displayFiles.length > 0 && (
          <>
            <div id="analysis">
              {displayFiles.length === 1 && successes === 0 && (
                <h1 className="text-align-center failure">
                  {t('form.analysis.lead_invalid')}
                </h1>
              )}
              {displayFiles.length === 1 && successes === 1 && (
                <h1 className="text-align-center success">
                  {t('form.analysis.lead_valid')}
                </h1>
              )}
              {displayFiles.length > 1 && (
                <h1 className="text-align-center">
                  {successes + '/' + displayFiles.length + ' '}
                  {t('form.analysis.lead_multi')}
                </h1>
              )}
              {displayFiles.map((item, index) => {
                return <Table key={index} content={item} />;
              })}
            </div>
          </>
        )}
      </div>
      <Poweredby></Poweredby>
    </div>
  );
};

export default VerifyHash;
