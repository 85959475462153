import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

const formatDate = (dateToFormat, dateFormat) => {
  const returnDate = moment(dateToFormat).tz('UTC').format(dateFormat);
  return returnDate;
};

const TableBase = ({ documentId, publicMetadata }) => {
  const { t } = useTranslation();

  const publicMetadataEntries = Object.keys(publicMetadata).map((key) => (
    <tr>
      <td>{key}</td>
      <td className="filename">{publicMetadata[key]}</td>
    </tr>
  ));

  return (
    <>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <tbody>
          <tr>
            <td>{t('form.analysis.table.document_id')}</td>
            <td className="filename">{documentId}</td>
          </tr>
          {publicMetadataEntries && publicMetadataEntries}
          <tr>
            <td>{t('form.analysis.table.status')}</td>
            <td className="description">
              <i className="int-green icon icon-check-mark"></i>{' '}
              {t('form.analysis.table.status-found')}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const TableValid = ({ content }) => {
  const { t } = useTranslation();
  const includeProtectedMetadata =
    process.env.REACT_APP_INCLUDE_PROTECTED_METADATA === 'true';
  const includePrivateMetadata =
    process.env.REACT_APP_INCLUDE_PRIVATE_METADATA === 'true';

  const protectedMetadataEntries =
    content.protectedMetadata && includeProtectedMetadata
      ? Object.keys(content.protectedMetadata).map((key) => (
          <tr>
            <td>{key}</td>
            <td className="filename">{content.protectedMetadata[key]}</td>
          </tr>
        ))
      : null;

  const privateMetadataEntries =
    content.privateMetadata && includePrivateMetadata
      ? Object.keys(content.privateMetadata).map((key) => (
          <tr>
            <td>{key}</td>
            <td className="filename">{content.privateMetadata[key]}</td>
          </tr>
        ))
      : null;

  return (
    <>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <tbody>
          {content.organization && (
            <tr>
              <td>{t('form.analysis.table.signed_by')}</td>
              <td>
                <b>{content.organization}</b>
              </td>
            </tr>
          )}
          {content.dateOfSubmission && (
            <tr>
              <td>{t('form.analysis.table.signed_at')}</td>
              <td>
                {formatDate(content.dateOfSubmission, t('form.dateTimeFormat'))}
              </td>
            </tr>
          )}
          {content.validFrom && content.validTo && (
            <tr>
              <td>{t('form.analysis.table.validity')}</td>
              <td>
                {formatDate(content.validFrom, t('form.dateFormat'))} –{' '}
                {formatDate(content.validTo, t('form.dateFormat'))}
              </td>
            </tr>
          )}
          {protectedMetadataEntries && protectedMetadataEntries}
          {privateMetadataEntries && privateMetadataEntries}
        </tbody>
      </table>
    </>
  );
};

const TableInvalid = ({ fileName }) => {
  const { t } = useTranslation();
  return (
    <>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <tbody>
          <tr>
            <td>{t('form.analysis.table.file')}</td>
            <td>{fileName}</td>
          </tr>
          <tr className="noBorder">
            <td>{t('form.analysis.table.status')}</td>
            <td>
              <i className="int-red icon icon-close"></i>
            </td>
          </tr>
          <tr>
            <td colSpan="2">{t('form.analysis.table.invalid')}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const TableDocumentId = ({ content }) => {
  const { t } = useTranslation();

  if (content['data'].hashValues.length === 0) {
    return (
      <>
        <h2 className="text-align-center">
          {t('form.analysis.lead_docId_invalid')}
        </h2>
        <div className="text">
          <TableInvalid fileName={content['base'].name} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <h2 className="text-align-center">
          {t('form.analysis.lead_docId_valid')}
        </h2>
        <h5 className="text-align-center">
          {t('form.analysis.lead_docId_explanation')}
        </h5>
        <div className="text">
          <TableBase
            documentId={content['documentId']}
            publicMetadata={content['data'].hashValues[0].publicMetadata}
          />
          {content['data'].hashValues.map((item, index) => {
            return (
              <TableValid key={index} content={content['data'].hashValues[0]} />
            );
          })}
        </div>
      </>
    );
  }
};

export default TableDocumentId;
