import CryptoJS from 'crypto-js';

class BigHash {
  constructor(chunkSize = 10) {
    this.lastOffset = 0;
    this.timeout = 0;
    this.chunkSize = chunkSize;
  }

  async createHash(file) {
    const SHA256 = CryptoJS.algo.SHA256.create();
    let counter = 0;

    return new Promise((resolve, reject) => {
      this.loading(
        file,
        (data) => {
          const wordBuffer = CryptoJS.lib.WordArray.create(data);
          SHA256.update(wordBuffer);
          counter += data.byteLength;
          console.log(((counter / file.size) * 100).toFixed(0) + '%');
        },
        () => {
          console.log('100%');
          const encrypted = SHA256.finalize().toString();
          //console.log('encrypted: ' + encrypted);
          clearTimeout(this.timeout);
          resolve(encrypted);
        }
      );
    });
  }

  loading(file, callbackProgress, callbackFinal) {
    const chunkSize = 1024 * 1024 * this.chunkSize; // bytes
    let offset = 0;
    const size = chunkSize;
    let partial;
    let index = 0;

    if (file.size === 0) {
      callbackFinal();
    }
    while (offset < file.size) {
      partial = file.slice(offset, offset + size);
      const reader = new FileReader();
      reader.size = chunkSize;
      reader.offset = offset;
      reader.index = index;
      reader.onload = (evt) => {
        this.callbackRead(reader, file, evt, callbackProgress, callbackFinal);
      };
      reader.readAsArrayBuffer(partial);
      offset += chunkSize;
      index += 1;
    }
  }

  callbackRead(reader, file, evt, callbackProgress, callbackFinal) {
    if (this.lastOffset === reader.offset) {
      // in order chunk
      this.lastOffset = reader.offset + reader.size;
      callbackProgress(evt.target.result);
      if (reader.offset + reader.size >= file.size) {
        callbackFinal();
      }
    } else {
      // not in order chunk
      setTimeout(() => {
        this.callbackRead(reader, file, evt, callbackProgress, callbackFinal);
      }, 10);
    }
  }
}

export default BigHash;
