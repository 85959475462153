import React from 'react';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const isCustomer =
  window.location.pathname === '/seal' ||
  window.location.pathname === '/seal/';

var Guide = (props) => {
  const { t } = props;
  var textkey = 'guide.text';

  if (isCustomer) {
    textkey = 'costumerguide.text';
  }

  const text = t(textkey, { returnObjects: true }).map((value, index) => {
    return (
      <p key={index} className="text-align-center">
        {value}
      </p>
    );
  });

  return (
    <div id="guide" className="section centered" name="guide">
      <div className="container">
        {isMobile && (
          <div className="infoBox">
            <p>
              <i className="icon icon-workstation"></i>
              {t('guide.mobile')}
            </p>
          </div>
        )}

        <div className="guided-steps">
          <ul>
            <li className="cloud"></li>
            <li className="separator"></li>
            <li className="search"></li>
            <li className="separator"></li>
            <li className="check-mark"></li>
          </ul>
        </div>
        <div className="text">
          <h2 className="h3 text-align-center">{t('guide.headline')}</h2>
          {text}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Guide);
