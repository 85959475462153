import React, { Suspense } from 'react';
import { AuthProvider } from '../context/auth.context';
import { Outlet } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';

import Guide from './Guide';

import '../styles/app.scss';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <div className="major-wrapper">
        <div className="main-wrapper flex">
          <AuthProvider>
            <Header />
            <div className="page">
              <Outlet />
              <Guide />
            </div>
          </AuthProvider>
          <Footer />
        </div>
      </div>
    </Suspense>
  );
};

export default App;
