import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import Dropzone from '../components/Dropzone';
import Loader from '../components/Loader';
import Table from '../components/Table';
import TableDocumentId from '../components/TableDocumentId';
import CryptoJS from 'crypto-js';
import eventBus from '../services/event-bus';
import Poweredby from '../components/Poweredby';
import { useSearchParams } from 'react-router-dom';
import ApiService from '../services/api-service';
import BigHash from '../utils/bigHash';

const Verify = () => {
  const [init, setInit] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [step, setStep] = useState(1);
  const [isVerified, setIsVerified] = useState(false);
  const [filesCount, setFilesCount] = useState([]);
  const [fetchingFiles, setFetchingFiles] = useState([]);
  const [previousFetchingFiles, setPreviousFetchingFiles] = useState([]);
  const [previousDisplayFiles, setPreviousDisplayFiles] = useState([]);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [successes, setSuccesses] = useState([]);
  const [error, setError] = useState({});
  const [documentId, setDocumentId] = useState('');
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  /*
        Normal path:
        Step 1 - Do init: Check file count, set init to true and show welcome screen
        Step 2 - Do captcha, set isVerified if captcha works, san skip if verified is already set
        Step 3 - Show drag and drop zone
        Step 4 - Fetch files, show spinner go to 3 if error
        Step 5 - show results, go to 3 if "check another"

        ValidateById path
        Step 10 - Do captcha, set isVerified if captcha works
        Step 11 - Fetch File, show spinner
        Step 12 - Show results
    */

  // file handler
  const maxSingleFileSize = 4294967295; // 4 GB
  const filesToVerifyAtATime = 100;

  const verifyDelay = 500; // ms

  const ApiEndpoint = process.env.REACT_APP_API_URL;
  const ApiVerifyByDocId = '/Hashes/verifyHashById';
  const ApiReport = '/Hashes/generateReport';
  const recapsitekey = process.env.REACT_APP_RECAP_SITE_KEY;

  useEffect(() => {
    if (window.location.href.indexOf('documentId') > -1 && step === 1) {
      // TODO: works only if documentId is before pin!
      var docId = window.location.href
        .replace(/.*documentId=/gi, '')
        .replace(/(\?|&)pin=.*/gi, '');
      var pin = window.location.href
        .replace(/.*pin=/gi, '')
        .replace(/(\?|&)documentId=.*/gi, '');

      setDocumentId(docId);
      setPin(pin);

      // go straight to captcha, jump over the button
      setStep(10);

      //            console.log('Using documentId path: ' + docId);
      //            console.log('Using document pin: ' + pin);
    }
  }, [step, setDocumentId, setPin]);

  useEffect(() => {
    eventBus.on('languageChanged', (data) => {
      if (i18n.language !== data.message) {
        setDisplayFiles([]);
        if (step === 5) {
          fetchFiles(
            previousFetchingFiles,
            ApiEndpoint,
            previousDisplayFiles,
            data.message
          );
        } else if (step === 12) {
          fetchFilesByDocumentId(
            step,
            documentId,
            pin,
            ApiEndpoint,
            previousDisplayFiles,
            data.message
          );
        }
      }
    });
  }, [step]);

  // init
  useEffect(() => {
    if (init === false) {
      //setting background as transparent in the embedded component
      //TODO find a better way of doing this. Problem is that by default css is not scoped on react
      // document.body.style.backgroundColor = 'transparent';
      document.documentElement.style.setProperty(
        '--backgroundColor',
        'transparent'
      );

      const theme = searchParams.get('theme');
      if (theme === 'dark') {
        // this is just used for testing for a hypothetical dark page to be embedded on
        //   document.documentElement.style.setProperty(
        //     '--backgroundColor',
        //     '#121212'
        //   );
        document.documentElement.style.setProperty('--textColor', 'white');
      }

      const accent = searchParams.get('accent');
      if (CSS.supports('color', '#' + accent)) {
        document.documentElement.style.setProperty(
          '--accentColor',
          '#' + accent
        );
      }

      //setting language
      const lang = searchParams.get('lang');
      if (['en', 'it', 'de', 'fr'].includes(lang)) {
        i18n.changeLanguage(lang);
      } else {
        i18n.changeLanguage('en');
      }

      // set verified to skip captcha
      if (JSON.parse(localStorage.getItem('eseal.verified')) === true) {
        setIsVerified(true);
      }

      // start it
      setInit(true);
    }
  }, [init, ApiEndpoint]);

  // set verified for future usage in same session
  useEffect(() => {
    if (isVerified === true) {
      localStorage.setItem('eseal.verified', true);
    }
  }, [isVerified]);

  // skip google captcha when already filled (same session)
  useEffect(() => {
    if ((step === 2 || step === 10) && isVerified === true) {
      //            console.log('Captcha already verified');

      setStep(step + 1);
    }
    if (step === 3) {
      setDisplayFiles([]);
    }

    if (step === 11) {
      setDisplayFiles([]);
    }
  }, [step, isVerified]);

  function fetchFiles(fetchingFiles, ApiEndpoint, displayFiles, lang) {
    setLoading(true);
    fetchingFiles.forEach(async (file) => {
      const bigHash = new BigHash(10);
      const hash = await bigHash.createHash(file);
      console.log('Hash for ', file.name , ' :', hash)

      ApiService.verifyFile(hash)
        .then((response) => {
          if (
            process.env.REACT_APP_BUILD === 'emoticom' &&
            file.name === 'fake-api-down-on-upload.txt'
          ) {
            setStep(3);
            setError({
              code: 'api-down-with-file',
              file: file.name,
            });
          } else if ('error' in response) {
            setStep(3);
            setError({
              code: 'api-down-with-file',
              file: file.name,
            });
          } else if ('noSeal' in response) {
            setError({});
            let data = {
              base: file,
              data: { hashValues: [] },
            };
            // console.log(response);
            // console.log('##', data);
            setDisplayFiles((displayFiles) => [...displayFiles, data]);
          } else {
            setError({});
            //console.log('######', response);
            //                        console.log("Lang: " + i18n.language);
            response.report =
              ApiEndpoint +
              ApiReport +
              '/' +
              hash +
              '/0/' +
              i18n.language +
              '/' +
              response.userId;
            let data = {
              base: file,
              data: { hashValues: [response] },
            };
            // console.log(response);
            // console.log('##', data);
            setDisplayFiles((displayFiles) => [...displayFiles, data]);
            // set current volume of used files
            // setFilesCount(response.callCount.callCount);
          }
        })
        .catch((error) => {
          setStep(3);
          setError({
            code: 'api-down-with-file',
            file: file.name,
          });
        });

      setLoading(false);
    });
  }

  function fetchFilesByDocumentId(
    step,
    documentId,
    pin,
    ApiEndpoint,
    displayFiles,
    lang
  ) {
    setLoading(true);
    let f = {
      documentId: documentId,
      pin: pin,
    };
    fetch(ApiEndpoint + ApiVerifyByDocId, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        documentId: f.documentId,
        pin: f.pin,
        language: lang,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          return { error: true };
        }
      })
      .then((response) => {
        if (Object.keys(response).length === 0 || 'error' in response) {
          setStep(3);
          setError({
            code: 'api-down-with-file',
            file: documentId,
          });
        } else {
          setError({});
          //                console.log(response);
          //                console.log("Lang: " + i18n.language);
          let data = {
            documentId: documentId,
            data: response,
          };
          setDisplayFiles((displayFiles) => [...displayFiles, data]);

          // set current volume of used files
          // setFilesCount(response.callCount.callCount);

          setStep(12);
        }
        setLoading(false);
      })
      .catch((error) => {
        setStep(3);
        setError({
          code: 'api-down-with-file',
          file: documentId,
        });
        setLoading(false);
      });
  }

  // fetch files
  useEffect(() => {
    if (fetchingFiles.length === 0 || loading) {
      return;
    }
    setPreviousFetchingFiles(fetchingFiles);
    setPreviousDisplayFiles(displayFiles);
    fetchFiles(fetchingFiles, ApiEndpoint, displayFiles, i18n.language);
    setFetchingFiles([]);
  }, [fetchingFiles, ApiEndpoint, displayFiles, i18n, loading]);

  // fetch document Id
  useEffect(() => {
    if (step !== 11 || loading) {
      return;
    }
    setPreviousDisplayFiles(displayFiles);
    fetchFilesByDocumentId(
      step,
      documentId,
      pin,
      ApiEndpoint,
      displayFiles,
      i18n.language
    );
  }, [step, documentId, pin, ApiEndpoint, displayFiles, i18n, loading]);

  // after files fetch
  useEffect(() => {
    if (displayFiles.length > 0 && step === 4) {
      setError({});
      setTimeout(() => {
        setFetchingFiles([]);
        setSuccesses(countSuccesses(displayFiles));
        setStep(5);
      }, verifyDelay);
    }
  }, [filesCount, displayFiles, step]);

  // after files fetch
  useEffect(() => {
    if (documentId !== '' && step === 12) {
      setError({});
      setTimeout(() => {
        setFetchingFiles([]);
      }, verifyDelay);
    }
  }, [documentId, step]);

  function countSuccesses(displayFiles) {
    return displayFiles.filter((x) => x.data.hashValues.length > 0).length;
  }

  return (
    <div id="embedded-form" className={`section step--${step}`}>
      <div id="used to center middle compoenent in flex"></div>
      <div className="container">
        {step === 1 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">{t('form.headline')}</h1>
              {Object.keys(error).length === 0 && init && (
                <>
                  <h2 className="text-align-center">{t('form.lead')}</h2>
                  {init && (
                    <div id="intro" className="text text-align-center">
                      <button className="button" onClick={() => setStep(2)}>
                        {t('form.intro.button')}
                      </button>
                    </div>
                  )}
                </>
              )}
              {Object.keys(error).length !== 0 && error.code === 'api-down' && (
                <>
                  <h2 className="text-align-center">
                    {t('form.error.api-down.lead')}
                  </h2>
                  <div className="text text-align-center">
                    <p className="text-align-center">
                      {t('form.error.api-down.text')}
                    </p>
                    <button
                      className="button"
                      onClick={() => window.location.reload(false)}>
                      {t('form.error.api-down.button')}
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {(step === 2 || step === 10) && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">{t('form.headline')}</h1>
              <h2 className="text-align-center">{t('form.lead')}</h2>
              <div className="text">
                <p className="text-align-center">{t('form.text')}</p>
              </div>
            </div>
            <div id="captcha" className="text">
              <div className="gcaptcha">
                <ReCAPTCHA
                  sitekey={recapsitekey}
                  size="normal"
                  onChange={() => {
                    if (step === 2) {
                      setStep(3);
                    } else {
                      setStep(11);
                    }
                    setIsVerified(true);
                  }}
                  hl={i18n.language}
                />
              </div>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">{t('form.headline')}</h1>
              <h2 className="text-align-center">{t('form.lead')}</h2>
              <div className="text">
                <p className="text-align-center">{t('form.text')}</p>
              </div>
            </div>
            <Dropzone
              isActive={isActive}
              maxSingleFileSize={maxSingleFileSize}
              filesToVerifyAtATime={filesToVerifyAtATime}
              onDrop={(value) => {
                setStep(4);
                setFetchingFiles(value);
              }}
              errorCode={error.code}
              errorFile={error.file}
            />
          </>
        )}
        {step === 4 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">{t('form.headline')}</h1>
              <h2 className="text-align-center">{t('form.lead')}</h2>
              <div className="text">
                <p className="text-align-center">{t('form.text')}</p>
              </div>
            </div>
            <div id="verify">
              <Loader />
              <ul className="text-align-center">
                {fetchingFiles.map((item, index) => {
                  return (
                    <li key={index}>
                      {t('form.verify.testing', { filename: item.name })}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
        {step === 11 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">{t('form.headline')}</h1>
              <h2 className="text-align-center">{t('form.lead')}</h2>
              <div className="text">
                <p className="text-align-center">{t('form.text')}</p>
              </div>
            </div>
            <div id="verify">
              <Loader />
              <ul className="text-align-center">
                {displayFiles.map((item, index) => {
                  return (
                    <li key={index}>
                      {t('form.verify.testing', { filename: documentId })}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
        {step === 5 && displayFiles.length > 0 && (
          <>
            <div id="analysis">
              {displayFiles.length === 1 && successes === 0 && (
                <h1 className="text-align-center failure">
                  {t('form.analysis.lead_invalid')}
                </h1>
              )}
              {displayFiles.length === 1 && successes === 1 && (
                <h1 className="text-align-center success">
                  {t('form.analysis.lead_valid')}
                </h1>
              )}
              {displayFiles.length > 1 && (
                <h1 className="text-align-center">
                  {successes + '/' + displayFiles.length + ' '}
                  {t('form.analysis.lead_multi')}
                </h1>
              )}
              {displayFiles.map((item, index) => {
                return <Table key={index} content={item} />;
              })}
              <div className="text">
                <div className="row flex-items-xs-right">
                  <div className="col-md-12 col-lg-6 text-align-right">
                    <button
                      className="button button-md-full"
                      onClick={() => {
                        setStep(3);
                      }}>
                      {t('form.analysis.button')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 12 && displayFiles.length > 0 && (
          <>
            <div id="analysis">
              <h1 className="text-align-center">{t('form.headline')}</h1>
              <TableDocumentId content={displayFiles[0]} />
            </div>
          </>
        )}
      </div>
      <Poweredby></Poweredby>
    </div>
  );
};

export default Verify;
