import React, { useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Text,
  Button,
  Container,
  Modal,
  Textarea,
} from '@nextui-org/react';
import { Delete, Edit, Password } from 'react-iconly';

const FilesTable = ({ files, onDescriptionChange, onFileRemove, onSeal }) => {
  const [visible, setVisible] = React.useState(false);
  const [descriptionId, setDescriptionId] = React.useState(0);
  const handleModalOpen = (id) => {
    setVisible(true);
    setDescriptionId(id);
  };
  const handleModalClose = () => {
    setVisible(false);
  };

  const filesToList = (files) => {
    return files.map((file, i) => ({
      id: i,
      filename: file.name,
      description: file.description ?? '',
      file: file,
    }));
  };

  const [list, setList] = useState(filesToList(files));

  useEffect(() => {
    setList(filesToList(files));
  }, [files]);

  const handleRemove = (id) => {
    onFileRemove(id);
  };
  const handleChange = (id, e) => {
    changeDescription(id, e.target.value);
  };

  const changeDescription = (id, value) => {
    setList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, description: value } : { ...item }
      )
    );
    onDescriptionChange(id, value);
  };

  const getDescription = (id) => {
    const item = list.find((item) => item.id === id);
    return item ? item.description : '';
  };

  const columns = [
    { name: 'FILE NAME', uid: 'filename' },
    { name: 'DESCRIPTION', uid: 'description' },
    { name: 'ACTIONS', uid: 'actions' },
  ];

  const renderCell = (item, columnKey) => {
    const cellValue = item[columnKey];
    switch (columnKey) {
      case 'filename':
        return (
          <Text b size={14}>
            {cellValue}
          </Text>
        );
      case 'description':
        return (
          <Row justify="center" align="center">
            <Col css={{ d: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
              <Textarea
                onClick={() => handleModalOpen(item.id)}
                fullWidth
                minRows={1}
                readOnly
                value={cellValue}
                aria-label="description"
              />
              <Button
                auto
                color="secondary"
                onPress={() => handleModalOpen(item.id)}
                aria-label="edit description"
                icon={
                  <Edit fill="currentColor" filled aria-label="Close" />
                }></Button>
            </Col>
          </Row>
        );
      case 'actions':
        return (
          <Row justify="center" align="center">
            <Col css={{ d: 'flex', justifyContent: 'flex-end' }}>
              <Button
                auto
                color="error"
                onPress={() => handleRemove(item.id)}
                aria-label="remove file"
                icon={
                  <Delete fill="currentColor" filled aria-label="Close" />
                }></Button>
            </Col>
          </Row>
        );
      default:
        return cellValue;
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        open={visible}
        onClose={handleModalClose}>
        <Modal.Header>
          <Text size={18}>Write a description.</Text>
        </Modal.Header>
        <Modal.Body>
          <Textarea
            autoFocus
            placeholder="Description "
            initialValue={getDescription(descriptionId)}
            onChange={(e) => handleChange(descriptionId, e)}
            aria-label="description"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="primary" onPress={handleModalClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid display="flex" justify="flex-end">
        <Button
          css={{ transform: 'scale(1.2)', marginRight: '10px' }}
          auto
          shadow
          color="primary"
          onPress={() => onSeal(list)}
          iconRight={
            <Password fill="currentColor" filled aria-label="Close" />
          }>
          SEAL ALL
        </Button>
      </Container>
      <Table
        aria-label="files table"
        css={{
          height: 'auto',
          marginTop: '10px',
          minWidth: '100%',
        }}
        shadow="none"
        selectionMode="none">
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column
              key={column.uid}
              hideHeader={column.uid === 'actions'}
              align={column.uid === 'actions' ? 'end' : 'start'}
              width={column.uid === 'actions' ? '100px' : 'max-content'}>
              {column.name}
            </Table.Column>
          )}
        </Table.Header>
        <Table.Body items={list}>
          {(item) => (
            <Table.Row onKey>
              {(columnKey) => (
                <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
              )}
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default FilesTable;
