import React, { createContext, useContext, useEffect, useState } from 'react';
// import decode from 'jwt-decode';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState('');

  // const isTokenExpired = useCallback(() => {
  //   try {
  //     const decoded = decode(token);
  //     return decoded.exp < Date.now() / 1000;
  //   } catch (err) {
  //     return false;
  //   }
  // }, [token]);

  useEffect(() => {
    setToken(getToken() || null);
  }, []);

  // useEffect(() => {
  //   if (token) {
  //     if (!isTokenExpired()) {
  //       setIsAuthenticated(true);
  //     } else {
  //       logout();
  //     }
  //   } else {
  //     setIsAuthenticated(false);
  //   }
  // }, [token, isTokenExpired]);

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [token]);

  function getToken() {
    return localStorage.getItem('eseal.accessToken');
  }

  function logout() {
    localStorage.removeItem('eseal.accessToken');
    setIsAuthenticated(false);
    window.location.reload();
  }

  function setAccessToken(token) {
    localStorage.setItem('eseal.accessToken', token);
    setIsAuthenticated(true);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        logout: logout,
        setAccessToken: setAccessToken,
        getToken: getToken,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
