import React from 'react';
import { Loading, Container, Row } from '@nextui-org/react';

const Loader = () => (
  <Container fluid>
    <Row justify="center" align="center">
      <Loading size="xl" />
    </Row>
  </Container>
);

export default Loader;
