const ApiEndpoint = process.env.REACT_APP_API_URL;
const VerifyToken = process.env.REACT_APP_API_TOKEN;
const ApiVerify = '/Hashes/verifyHash';
const ApiReport = '/Hashes/generateReport';
const ApiSeal = '/Hashes/createHash';
const ApiMagicLink = '/Organizations/magicLink';
const ApiHealth = '/Health';

const ApiService = {
  verifyFile: async function (hashToVerify) {
    return fetch(ApiEndpoint + ApiVerify, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: VerifyToken,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        hashValue: hashToVerify,
        // hashValue512: f.hashToVerify512,
        // language: lang,
      }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 404) {
        return { noSeal: true };
      } else {
        return { error: true };
      }
    });
  },
  sealFile: async function (hashToStore, description, fileName, authToken) {
    return fetch(ApiEndpoint + ApiSeal, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authToken}`,
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        hashValue: hashToStore,
        userId: '',
        validityType: 'no_validity_type',
        publicMetadata: {
          publicFileName: fileName,
          publicDescription: description,
        },
        protectedMetadata: '',
        privateMetadata: '',
      }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return {
          error: true,
          status: res.status,
        };
      }
    });
  },

  sendMagicLink: async function (emailAddress) {
    return fetch(ApiEndpoint + ApiMagicLink, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        email: emailAddress,
      }),
    }).then((res) => {
      if (res.status === 204) {
        return res;
      } else {
        return { error: true };
      }
    });
  },

  checkHealth: async function (authToken) {
    return fetch(ApiEndpoint + ApiHealth, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authToken}`,
      },
      referrerPolicy: 'no-referrer',
    }).then((res) => {
      if (res.status === 204) {
        return res;
      } else {
        return { error: true };
      }
    });
  },
};

export default ApiService;
