import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/auth.context';

import Navigation from '../components/Navigation';

const Header = () => {
  const { t } = useTranslation();

  return (
    <header id="header">
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.description')} />
      </Helmet>
      <Navigation></Navigation>
    </header>
  );
};

export default Header;
