import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({
  isActive,
  maxFilesToVerify,
  maxSingleFileSize,
  filesToVerifyAtATime,
  onDrop,
  errorCode,
  errorFile,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileLength, setFileLength] = useState(0);
  const [files, setFiles] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (fileLength > 0 && files.length === fileLength) {
      onDrop(files);
    }
  }, [files, fileLength, onDrop]);

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFilesToVerify,
    multiple: filesToVerifyAtATime > 1 ? true : false,
    maxSize: maxSingleFileSize,
    disabled: !isActive,
    onDragEnter: (event) => {
      setIsDragging(true);
    },
    onDragLeave: (event) => {
      setIsDragging(false);
    },
    onDrop: (acceptedFiles) => {
      setIsDragging(false);
      setFileLength(acceptedFiles.length);
      setFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    setErrorMessage('');
    if (!isActive) {
      setErrorMessage(
        t('form.file.error.reachedMax', { maxFilesToVerify: maxFilesToVerify })
      );
    } else if (fileRejections.length > filesToVerifyAtATime) {
      setErrorMessage(
        t('form.file.error.too-many-files', {
          filesToVerifyAtATime: filesToVerifyAtATime,
        })
      );
    } else if (
      Math.max.apply(
        Math,
        fileRejections.map(function (o) {
          return o.file.size;
        })
      ) > maxSingleFileSize
    ) {
      setErrorMessage(t('form.file.error.file-too-large'));
    } else if (errorCode === 'api-down-with-file') {
      setErrorMessage(t('form.file.error.api-down', { filename: errorFile }));
    } else if (errorCode === 'file-already-certified') {
      setErrorMessage(
        t('customer.form.file.error.already-certified', { filename: errorFile })
      );
    }
  }, [
    fileRejections,
    isActive,
    maxFilesToVerify,
    filesToVerifyAtATime,
    maxSingleFileSize,
    t,
    errorCode,
    errorFile,
    files,
  ]);

  var classes = 'dropzone--container';
  if (!isActive) {
    classes += ' is-inactive';
  }
  if (errorMessage !== '') {
    classes += ' has-error';
  }
  if (isDragging) {
    classes += ' is-drag';
  }

  return (
    <div id="file" className={classes}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div className="hidden-md-down">
          <p className="drop">{t('form.file.drop')}</p>
          <p className="or">{t('form.file.or')}</p>
        </div>
        <button className="button transparent">
          <i className="icon icon-attachment"></i>
          {t('form.file.select')}
        </button>
      </div>
      {errorMessage !== '' && (
        <div className="dropzone--error">
          <i className="icon icon-exclamation-mark"></i>{' '}
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
