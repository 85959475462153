import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
  const { t } = useTranslation();

  const linkList = t('footer.links', { returnObjects: true }).map(
    (value, index) => {
      return (
        <li key={index} className="nav-item">
          <a
            className="nav-link"
            href={value.url}
            target="_blank"
            rel="noreferrer">
            {value.label}
          </a>
        </li>
      );
    }
  );

  return (
    <footer className="footer footer--full footer--light">
      <div className="footer__bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12 col-lg">
              <div className="footer--nav footer--left">
                <ul className="nav-items nav-items--divider">{linkList}</ul>
              </div>
            </div>
            <div className="col-xs-12 col-md-12 col-lg flex-lg-last">
              <div className="footer--nav footer--right">
                {t('footer.copyright')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
