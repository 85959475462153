import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import eventBus from '../services/event-bus';
import { Dropdown } from '@nextui-org/react';

const Language = () => {
  const { i18n } = useTranslation();
  const [langInit, setLangInit] = useState(false);

  const handleChange = (lng) => {
    eventBus.dispatch('languageChanged', { message: lng });
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    if (!langInit) {
      let lang = i18n.language.split('-')[0];
      i18n.changeLanguage(lang);
      setLangInit(true);
    }
  }, [langInit, i18n]);

  const dorpDownItems = [
    { key: 'de', name: 'DE' },
    { key: 'fr', name: 'FR' },
    { key: 'it', name: 'IT' },
    { key: 'en', name: 'EN' },
  ];

  return (
    <Dropdown>
      <Dropdown.Button light>{i18n.language.toUpperCase()}</Dropdown.Button>
      <Dropdown.Menu
        onAction={(key) => handleChange(key)}
        items={dorpDownItems.filter((x) => x.key != i18n.language)}
        aria-label="Language Selection">
        {(item) => <Dropdown.Item key={item.key}>{item.name}</Dropdown.Item>}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Language;
