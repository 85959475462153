import React from 'react';
import EsealLogo from '../images/eseal-logo.svg';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/auth.context';
import { Navbar, Button, Link } from '@nextui-org/react';

import Language from './Language';

const Navigation = () => {
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useAuth();

  return (
    <Navbar variant="static">
      <Navbar.Toggle showIn="xs" aria-label="toggle navigation" />
      <Navbar.Brand className="navbar-content--left">
        <div className="nav-logo">
          <a href="./" className="" tabIndex="1">
            <img src={EsealLogo} className="eseal-logo" alt="E-Seal Logo" />
          </a>
        </div>
      </Navbar.Brand>
      <Navbar.Content variant="highlight-rounded" hideIn="xs">
        <Navbar.Link
          isActive={
            window.location.pathname === '/verify' ||
            window.location.pathname === '/'
          }
          href="verify">
          {t('customer.navigation.verify_link')}
        </Navbar.Link>
        <Navbar.Link
          isActive={window.location.pathname === '/seal'}
          href="seal">
          {t('customer.navigation.seal_link')}
        </Navbar.Link>
      </Navbar.Content>
      <Navbar.Content className="navbar-content--right">
        {isAuthenticated && (
          <Navbar.Item hideIn="xs">
            <Button auto flat rounded onPress={() => logout()}>
              {t('customer.navigation.logout_link')}
            </Button>
          </Navbar.Item>
        )}
        <Navbar.Item>
          <Language />
        </Navbar.Item>
      </Navbar.Content>
      <Navbar.Collapse disableAnimation>
        <Navbar.CollapseItem>
          <Link
            color="inherit"
            css={{
              minWidth: '100%',
            }}
            href="verify">
            {t('customer.navigation.verify_link')}
          </Link>
        </Navbar.CollapseItem>
        <Navbar.CollapseItem>
          <Link
            color="inherit"
            css={{
              minWidth: '100%',
            }}
            href="seal">
            {t('customer.navigation.seal_link')}
          </Link>
        </Navbar.CollapseItem>
        {isAuthenticated && (
          <Navbar.CollapseItem>
            <Link
              color="inherit"
              css={{
                minWidth: '100%',
              }}
              onPress={() => logout()}>
              {t('customer.navigation.logout_link')}
            </Link>
          </Navbar.CollapseItem>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
