import React, { useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Input,
  Text,
  Button,
  Container,
  Badge,
  Link,
  Textarea,
} from '@nextui-org/react';
import { Document, ChevronLeft } from 'react-iconly';

const SealedFilesTable = ({
  alreadyCertifiedFiles,
  certifiedDocuments,
  onBack,
}) => {
  const filesToList = (files) => {
    return files.map((file, i) => ({
      id: certifiedDocuments.length + i,
      filename: file.filename,
      description: '',
      hash: file.hash,
      newSeal: false,
    }));
  };

  const docsToList = (docs) => {
    return docs.map((doc, i) => ({
      id: i,
      filename: doc.publicMetadata.publicFileName,
      description: doc.publicMetadata.publicDescription,
      hash: doc.hashValue,
      report: doc.report,
      newSeal: true,
    }));
  };

  const [list, setList] = useState([
    ...docsToList(certifiedDocuments),
    ...filesToList(alreadyCertifiedFiles),
  ]);

  useEffect(() => {
    setList([
      ...docsToList(certifiedDocuments),
      ...filesToList(alreadyCertifiedFiles),
    ]);
  }, [alreadyCertifiedFiles, certifiedDocuments]);

  const columns = [
    { name: 'FILE NAME', uid: 'filename' },
    { name: 'DESCRIPTION', uid: 'description' },
    { name: 'HASH', uid: 'hash' },
    { name: 'REPORT', uid: 'report' },
    { name: 'STATUS', uid: 'status' },
  ];

  const renderCell = (item, columnKey) => {
    const cellValue = item[columnKey];
    switch (columnKey) {
      case 'filename':
        return (
          <Text b size={14}>
            {cellValue}
          </Text>
        );
      case 'description':
        return (
          <Textarea
            fullWidth
            minRows={1}
            readOnly
            value={cellValue}
            aria-label="description"
          />
        );
      case 'hash':
        return (
          <Input
            width="100%"
            contentRightStyling={false}
            initialValue={cellValue}
            readOnly
            aria-label="hash"
            contentRight={
              <Button
                auto
                flat
                aria-label="copy"
                onPress={() => navigator.clipboard.writeText(cellValue)}
                // icon={
                //   <Document fill="currentColor" filled aria-label="Close" />
                // }
              >
                copy
              </Button>
            }
          />
        );
      case 'report':
        return (
          <a href={cellValue} target="_blank">
            <Button
              auto
              aria-label="remove file"
              disabled={!item.newSeal}
              iconRight={
                <Document fill="currentColor" filled aria-label="Close" />
              }></Button>
          </a>
        );
      case 'status':
        return (
          <Badge color={item.newSeal ? 'success' : 'error'}>
            {item.newSeal ? 'Sealed' : 'Already Sealed'}
          </Badge>
        );
      default:
        return cellValue;
    }
  };
  return (
    <>
      <Container
        css={{ d: 'flex', justifyContent: 'flex-start', marginTop: '25px' }}>
        <Button
          auto
          color="primary"
          onPress={() => onBack(list)}
          iconRight={
            <ChevronLeft fill="currentColor" filled aria-label="Close" />
          }>
          BACK
        </Button>
      </Container>
      <Table
        aria-label="Example table with custom cells"
        css={{
          height: 'auto',
          marginTop: '10px',
          minWidth: '100%',
        }}
        shadow="none"
        selectionMode="none">
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column
              key={column.uid}
              align={column.uid === 'status' ? 'center' : 'start'}
              width={
                column.uid === 'status'
                  ? '120px'
                  : column.uid === 'report'
                  ? '80px'
                  : ''
              }>
              {column.name}
            </Table.Column>
          )}
        </Table.Header>
        <Table.Body items={list}>
          {(item) => (
            <Table.Row>
              {(columnKey) => (
                <Table.Cell
                  css={{
                    verticalAlign: 'middle',
                  }}>
                  {renderCell(item, columnKey)}
                </Table.Cell>
              )}
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default SealedFilesTable;
