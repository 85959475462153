import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './views/App';
import Error from './views/Error';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';

// import i18n (needs to be bundled ;))
import './i18n';
import CustomerForm from './views/CustomerForm';
import Multiform from './views/Multiform';
import Embedded from './views/Embedded';
import Verify from './views/Verify';
import VerifyHash from './views/VerifyHash';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: '',
        element: <Multiform />,
      },
      {
        path: 'verify',
        element: <Multiform />,
      },
      {
        path: 'seal',
        element: <CustomerForm />,
      },
    ],
  },
  {
    path: '/embedded',
    element: <Embedded />,
    errorElement: <Error />,
    children: [
      {
        path: '',
        element: <Verify />,
      },
      {
        path: 'verify',
        element: <Verify />,
      },
      {
        path: 'verify/:hash',
        element: <VerifyHash />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
