import React from 'react';

const Jumper = () => {
  return (
    <a
      id="jump"
      href="#guide"
      onClick={(event) => {
        event.preventDefault();
        var pos = document.getElementById('guide').offsetTop;
        window.scrollTo({ top: pos, left: 0, behavior: 'smooth' });
      }}>
      <i className="icon icon-chevron-flat-down"></i>
    </a>
  );
};

export default Jumper;
