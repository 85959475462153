import React from 'react';
import EsealLogo from '../images/eseal-logo.svg';

const Poweredby = () => {
  return (
    <div className="poweredby-container">
      <div>Powered by</div>
      <a href="https://e-seal.ch/" target="_blank"><img src={EsealLogo} alt="E-Seal Logo" /></a>
    </div>
  );
};

export default Poweredby;
