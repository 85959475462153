import React, { Suspense } from 'react';
import { AuthProvider } from '../context/auth.context';
import { Outlet } from 'react-router-dom';

import Loader from '../components/Loader';

import '../styles/embedded.scss';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const Embedded = () => {
  return (
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        <div className="embedded-container">
          <Outlet />
        </div>
      </AuthProvider>
    </Suspense>
  );
};

export default Embedded;
