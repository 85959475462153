import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/auth.context';
import { useSearchParams } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';
import Dropzone from '../components/Dropzone';
import Jumper from '../components/Jumper';
import CryptoJS from 'crypto-js';
import ApiService from '../services/api-service';
import SealedFilesList from '../components/SealedFilesList';
import FilesTable from '../components/FilesTable';
import SealedFilesTable from '../components/SealedFilesTable';
import Loader from '../components/Loader';
import BigHash from '../utils/bigHash';

const CustomerForm = () => {
  const [init, setInit] = useState(false);
  const [step, setStep] = useState(1);
  const [isVerified, setIsVerified] = useState(false);
  const [fetchingFiles, setFetchingFiles] = useState([]);
  const [filesToSeal, setFilesToSeal] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const [emailSecret, setEmailSecret] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidSecret, setIsInvalidSecret] = useState(false);
  const [certifiedDocuments, setCertifiedDocuments] = useState([]);
  const [alreadyCertifiedFiles, setAlreadyCertifiedFiles] = useState([]);
  const [sealed, setSealed] = useState(false);

  const { t, i18n } = useTranslation();
  const { isAuthenticated, setAccessToken, getToken, logout } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  /*
        Normal path:
        Step 1 - Do init: Set init to true and show welcome screen
        Step 2 - Do captcha, set isVerified if captcha works, san skip if verified is already set
        Step 3 - Authenticate user
        Step 4 - Certify files form
    */

  // file handler
  const maxSingleFileSize = 4294967295; // 4 GB
  const maxFilesToVerify = 100;
  const filesToVerifyAtATime = 100;

  const ApiEndpoint = process.env.REACT_APP_API_URL;
  const ApiEmail = '/Certifications/getSecret';
  const ApiGetToken = '/Certifications/getToken';
  const ApiCertify = '/Certifications/performCertification';
  const ApiCounts = '/CallCounts/callCount';
  const ApiReport = '/Hashes/generateReport';
  const recapsitekey = process.env.REACT_APP_RECAP_SITE_KEY;

  useEffect(() => {
    if (init === false) {
      // fetch(ApiEndpoint + ApiCounts)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       return res;
      //     } else {
      //       return { error: true };
      //     }
      //   })
      //   .then((response) => {
      //     if ('error' in response) {
      //       setError({ code: 'api-down' });
      //     } else {
      //       // set verified to skip captcha
      //       if (JSON.parse(localStorage.getItem('eseal.verified')) === true) {
      //         setIsVerified(true);
      //       }

      //       // start it
      //       setInit(true);
      //     }
      //   })
      //   .catch(() => {
      //     setError({ code: 'api-down' });
      //   });

      // set verified to skip captcha
      if (JSON.parse(localStorage.getItem('eseal.verified')) === true) {
        setIsVerified(true);
      }

      //logging in with token
      const token = searchParams.get('token');
      if (
        token &&
        token !== '' //verify token
      ) {
        checkToken(token);
        //removing token from url params
        searchParams.delete('token');
        setSearchParams(searchParams);
      }

      // start it
      setInit(true);
    }
  }, [
    init,
    ApiEndpoint,
    searchParams,
    setSearchParams,
    isAuthenticated,
    setAccessToken,
  ]);

  // set verified for future usage in same session
  useEffect(() => {
    if (isVerified === true) {
      localStorage.setItem('eseal.verified', true);
    }
  }, [isVerified]);

  // skip google captcha when already filled (same session)
  useEffect(() => {
    if ((step === 2 || step === 10) && isVerified === true) {
      setStep(step + 1);
    }
  }, [step, isVerified]);

  // certify files
  useEffect(() => {
    if (fetchingFiles.length === 0 || loading) {
      return;
    }

    setFilesToSeal([...filesToSeal, ...fetchingFiles]);
    setAlreadyCertifiedFiles([]);
    setCertifiedDocuments([]);
  }, [fetchingFiles, ApiEndpoint]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //if authenticated but not verified go to the captcha
    if (isAuthenticated & !isVerified) {
      setStep(2);
    }

    //if verified but not authenticated go to the login with magic link
    if (!isAuthenticated & isVerified & (step != 1)) {
      setStep(3);
    }

    //if authenticated and verified go to 4
    if (isAuthenticated & isVerified) {
      setStep(4);
    }
  }, [isAuthenticated, isVerified, step]);

  // reset verified email status if email email address changes
  useEffect(() => {
    setIsEmailVerified(false);
    setIsMagicLinkSent(false);
  }, [emailAddress]);

  // verify email by backend
  const verifyEmail = () => {
    setLoading(true);

    fetch(ApiEndpoint + ApiEmail, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        email: emailAddress,
        lang: i18n.language,
      }),
    })
      .then((res) => {
        if (res.status === 204) {
          return res;
        } else {
          return { error: true };
        }
      })
      .then((response) => {
        if ('error' in response) {
          setIsInvalidEmail(true);
        } else {
          setIsEmailVerified(true);
        }
      })
      .catch(() => {
        setError({ code: 'api-down' });
      })
      .finally(() => setLoading(false));
  };

  // send magic link
  const magicLink = () => {
    setLoading(true);

    ApiService.sendMagicLink(emailAddress)
      .then((response) => {
        if ('error' in response) {
          // console.log('magic Link invalid');
          setIsMagicLinkSent(false);
        } else {
          // console.log('magic Link verified');
          setIsMagicLinkSent(true);
        }
      })
      .catch(() => {
        setError({ code: 'api-down' });
      })
      .finally(() => setLoading(false));
  };

  // check token validity
  const checkToken = (token) => {
    setLoading(true);

    ApiService.checkHealth(token)
      .then((response) => {
        if (response.status === 204) {
          // console.log('token valid');
          //setting token in auth
          setAccessToken(token);
        } else {
          // console.log('token invalid');
        }
      })
      .catch(() => {
        setError({ code: 'api-down' });
      })
      .finally(() => setLoading(false));
  };

  /*
    verify secret by backend, set user to be authenticated and accept cookie,
    go to step 4
    */
  // const verifySecret = () => {
  //   setLoading(true);

  //   fetch(ApiEndpoint + ApiGetToken, {
  //     method: 'POST',
  //     cache: 'no-cache',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     referrerPolicy: 'no-referrer',
  //     body: JSON.stringify({
  //       email: emailAddress,
  //       otp: emailSecret,
  //     }),
  //   })
  //     .then((res) => {
  //       if (res.status !== 200) {
  //         return { error: true };
  //       } else {
  //         return res.json();
  //       }
  //     })
  //     .then((response) => {
  //       if ('error' in response) {
  //         setIsInvalidSecret(true);
  //       } else {
  //         setIsInvalidSecret(false);
  //         if (response?.jwt) {
  //           setJwt(response.jwt);
  //         }
  //         setEmailSecret('');
  //         setEmailAddress('');
  //         setStep(4);
  //       }
  //     })
  //     .catch(() => {
  //       setError({ code: 'api-down' });
  //     })
  //     .finally(() => setLoading(false));
  // };

  // step 3 login
  // function verifyEmailAndSecret(event) {
  //   event.preventDefault();
  //   if (isEmailVerified) {
  //     verifySecret();
  //   } else {
  //     verifyEmail();
  //   }
  // }

  function sendMagicLink(event) {
    event.preventDefault();
    magicLink();
  }

  // certify files function
  function certifyFiles(items) {
    setFilesToSeal([]);
    setAlreadyCertifiedFiles([]);
    setCertifiedDocuments([]);
    setLoading(true);
    items.forEach(async (item) => {
      const bigHash = new BigHash(10);
      const hash = await bigHash.createHash(item.file);
      console.log('Hash for ', item.filename, ' :', hash);
      item.hash = hash;

      ApiService.sealFile(hash, item.description, item.filename, getToken())
        .then((response) => {
          if (Object.keys(response).length === 0 || 'error' in response) {
            if (response?.status === 409) {
              // setError({
              //   code: 'file-already-certified',
              //   file: file.filename,
              // });
              setAlreadyCertifiedFiles((alreadyCertifiedFiles) => [
                ...alreadyCertifiedFiles,
                item,
              ]);
            } else if (response?.status === 401) {
              return logout();
            } else {
              setError({
                code: 'api-down-with-file',
                file: item.filename,
              });
            }
          } else {
            console.log('##### ', response);
            response.report =
              ApiEndpoint +
              ApiReport +
              '/' +
              hash +
              '/0/' +
              i18n.language +
              '/00000000-0000-0000-0000-000000000000';
            setCertifiedDocuments((certifiedDocuments) => [
              ...certifiedDocuments,
              response,
            ]);
            setError({});
          }
        })
        .catch(() => {
          setError({
            code: 'api-down-with-file',
            file: item.filename,
          });
        })
        .finally(() => setLoading(false));
    });
  }

  return (
    <div id="form" className={`section step--${step}`}>
      <div className="container">
        {step === 1 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">
                {t('customer.form.headline')}
              </h1>
              {Object.keys(error).length === 0 && init && (
                <>
                  <h2 className="text-align-center">
                    {t('customer.form.lead')}
                  </h2>
                  {init && (
                    <div id="intro" className="text text-align-center">
                      <button className="button" onClick={() => setStep(2)}>
                        {t('customer.intro.login_button')}
                      </button>
                    </div>
                  )}
                </>
              )}
              {Object.keys(error).length !== 0 && error.code === 'api-down' && (
                <>
                  <h2 className="text-align-center">
                    {t('form.error.api-down.lead')}
                  </h2>
                  <div className="text text-align-center">
                    <button
                      className="button"
                      onClick={() => window.location.reload(false)}>
                      {t('form.error.api-down.button')}
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">
                {t('customer.form.headline')}
              </h1>
              <h2 className="text-align-center">{t('customer.form.lead')}</h2>
            </div>
            <div id="captcha" className="text">
              <div className="gcaptcha">
                <ReCAPTCHA
                  sitekey={recapsitekey}
                  size="normal"
                  onChange={() => {
                    isAuthenticated ? setStep(4) : setStep(3);
                    setIsVerified(true);
                  }}
                  hl={i18n.language}
                />
              </div>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">
                {t('customer.form.headline')}
              </h1>
              <h2 className="text-align-center">{t('customer.form.lead')}</h2>
            </div>
            {isMagicLinkSent && (
              <div className="textblock">
                <br />
                <h4 className="text-align-center">Check your email</h4>
                <p className="text-align-center">
                  We sent an Email to you at <b>{emailAddress}</b>. Follow the
                  magic link to log in.
                </p>
              </div>
            )}
            {!isMagicLinkSent && (
              <form onSubmit={sendMagicLink} className="paragraph">
                <div className="row">
                  <div className="col margin-bottom-2">
                    <div
                      className={`component text editable ${
                        isInvalidEmail ? 'invalid' : ''
                      }`}>
                      <label className="label" htmlFor="1">
                        {t('customer.form.email_label')}
                        <span aria-hidden="true">&nbsp;*</span>
                      </label>
                      <div className="wrapper">
                        <input
                          value={emailAddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                          id="1"
                          placeholder={t(
                            'customer.form.placeholder_email_address'
                          )}
                          autoCorrect="off"
                          autoCapitalize="off"
                          required
                          className="input"
                          type="email"
                          spellCheck="false"
                        />
                      </div>
                      {isInvalidEmail && (
                        <div className="component">
                          <i
                            className="icon icon-exclamation-mark icon--s2"
                            aria-hidden="true"
                          />
                          <span>{t('customer.form.email_error')}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row" aria-hidden="true">
                  <div className="col margin-bottom-2">
                    <div className="component text">
                      <span className="text-small">
                        * {t('customer.form.mandatory')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="component text button-submit">
                      <button
                        className="button button-reset primary"
                        type="submit">
                        {t('customer.form.submit_button')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </>
        )}

        {step === 4 && (
          <>
            <div className="textblock">
              <h1 className="text-align-center">
                {t('customer.form.headline')}
              </h1>
              <h2 className="text-align-center">{t('customer.form.lead')}</h2>
              <div className="text">
                <p className="text-align-center">{t('customer.form.text')}</p>
              </div>
            </div>

            {loading && (
              <>
                <div className="row">
                  <div className="col margin-top-3">
                    <div className="component text">
                      <Loader />
                    </div>
                  </div>
                </div>
              </>
            )}

            {!sealed && (
              <Dropzone
                isActive={true}
                maxFilesToVerify={maxFilesToVerify}
                maxSingleFileSize={maxSingleFileSize}
                filesToVerifyAtATime={filesToVerifyAtATime}
                onDrop={(value) => {
                  setFetchingFiles(value);
                }}
                errorCode={error.code}
                errorFile={error.file}
              />
            )}
            {filesToSeal.length > 0 && (
              <>
                <div className="row">
                  <div className="col">
                    <FilesTable
                      files={filesToSeal}
                      onDescriptionChange={(id, value) => {
                        const newFiles = [...filesToSeal];
                        newFiles[id].description = value;
                        setFilesToSeal(newFiles);
                      }}
                      onFileRemove={(id) => {
                        setFilesToSeal(
                          filesToSeal.filter((file, i) => i !== id)
                        );
                      }}
                      onSeal={(files) => {
                        setSealed(true);
                        certifyFiles(files);
                      }}></FilesTable>
                  </div>
                </div>
              </>
            )}
            {sealed &&
              (alreadyCertifiedFiles.length > 0 ||
                certifiedDocuments.length > 0) && (
                <div className="row">
                  <div className="col">
                    <SealedFilesTable
                      alreadyCertifiedFiles={alreadyCertifiedFiles ?? []}
                      certifiedDocuments={certifiedDocuments ?? []}
                      onBack={() => {
                        setSealed(false);
                      }}></SealedFilesTable>
                  </div>
                </div>
              )}
          </>
        )}
      </div>
      {step === 1 && <Jumper />}
    </div>
  );
};

export default CustomerForm;
